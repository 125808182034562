import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import UserSetting, {
	UserRulesEntity,
	UserSettingBody,
	UserSettingField,
} from "@/models/Setting";
import { Profile, UserRules } from "@/interfaces/user";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import Separator from "@/components/Content/Separator.vue";
import ImageSelector from "@/components/Content/Uploader/ImageSelector.vue";
import CardActions from "@/components/Content/CardActions.vue";
import { isRequired, isEmail } from "@/services/rule-services";
import { TypeLoading } from "@/interfaces/loading";
import { UserLanguage } from "@/interfaces/internalization";
import { ComboListOptions } from "@/utils/resolveObjectArray";

export default Vue.extend({
	name: "SettingsEdit",
	props: {},
	components: {
		CardAutocomplete,
		CardTextField,
		CardSwitch,
		Separator,
		CardActions,
		ImageSelector,
	},
	data: () => ({
		valid: true,
		userSetting: new UserSetting(),
		userRules: new UserRulesEntity(),
	}),
	created() {
		this.$nextTick(() => {
			this.prepareUserSettingData();
		});
	},
	mounted() {},
	beforeDestroy() {},
	computed: {
		...mapGetters("profile", {
			getUserSetting: "profile",
		}),
		...mapGetters("internationalization", ["getLanguages", "getLanguage"]),

		getLanguagesItems(): ComboListOptions[] {
			return this.getLanguages;
		},

		getUserSettingParams() {
			let userSettingBody: UserSettingBody = {
				name: this.userSetting.name,
				last_name: this.userSetting.last_name,
				role_id: this.userSetting.role_id,
				language: this.userSetting.language,
				avatar_file: this.userSetting.upload_file,
			};

			return {
				id: this.userSetting.id,
				user: userSettingBody,
			};
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("user_setting", {
			updateUserSetting: "update",
		}),
		...mapActions("internationalization", ["setLanguage"]),
		...mapActions("profile", ["setProfileLanguage", "setProfileData"]),

		/**
		 * Update Language (Api)
		 * @param lang
		 */
		async updateLang() {
			const params: UserLanguage = {
				id: this.getUserSetting.id,
				language: this.userSetting.language,
			};
			await this.setProfileLanguage(params.language);
			await this.setLanguage(params);
			this.$i18n.locale = this.getLanguage;
		},

		prepareUserSettingData() {
			const profile: Profile = this.getUserSetting;
			let userSetting = new UserSetting(profile);
			this.userSetting = userSetting;
		},
		resetValidation() {
			let form = this.$refs.form;
			form.resetValidation();
		},
		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},
		async addRules() {
			const userRules: UserRules = {
				id: [isRequired],
				email: [isRequired, isEmail],
				name: [isRequired],
				last_name: [isRequired],
				avatar_file: [],
				language: [isRequired],
				role_id: [],
				active: [],
			};
			await this.userRules.setRules(userRules);
		},

		async handleSubmit(event: any) {
			try {
				if (event.type !== "submit") return;

				await this.addRules();

				if (!(await this.validate())) return;

				this.setLoadingData(TypeLoading.loading);

				await this.updateUserSetting(this.getUserSettingParams);
				await this.updateLang();
				await this.setProfileData({
					key: "avatar_file",
					value: this.userSetting.avatar_file,
				});

				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},
		redirectTo() {},
		handleUpdateUpload(event: {
			avatar_file: string | null;
			upload_file: File | null;
		}) {
			const { avatar_file, upload_file } = event;

			// upload_file
			this.userSetting.setData(UserSettingField.UPLOAD_FILE, upload_file);

			// avatar_file
			this.userSetting.setData(UserSettingField.AVATAR_FILE, avatar_file);
		},
	},
	watch: {
		getLanguage(val) {
			this.userSetting.setData(UserSettingField.LANGUAGE, val);
			this.$i18n.locale = val;
		},
	},
});
